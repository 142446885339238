import PropTypes from 'prop-types';
import React from 'react';
import { Space, Typography, Button, Layout } from 'antd';
import {
  ArrowLeftOutlined,
} from '@ant-design/icons';

const { Header } = Layout;
const { Text } = Typography;

const HeaderComponent = ({ goTo, title, icon, onClick }) => (
  <Header className="site-layout-background" style={{ padding: 0 }}>
    <Space size="middle" direction="horizontal">
      <Button style={{ textAlign: 'left' }}
        className="icon-color-primary"
        type="text" size="large"
        onClick={onClick}
        href={goTo} icon={icon ? icon : <ArrowLeftOutlined />} />
      <Text strong>{title}</Text>
    </Space>
  </Header>
);

HeaderComponent.propTypes = {
  goTo: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.string
};

HeaderComponent.defaultProps = {
  goTo: '',
  title: '',
  icon: '',
  onClick: ''
};

export default HeaderComponent;
