import React from 'react';
import { Row, Col, Typography, Layout, Space, Button, Divider } from 'antd';
import '../styles/order-details.scss';
import Map from '../components/map/map';
const { Title, Text } = Typography;
import HeaderComponent from '../components/header/header';

const { Content } = Layout;

const OrderDetails = () => {
  const cost = '$7.00';
  return (
    <Layout className="site-layout-background">
      <HeaderComponent title="Detalle del Contrato #1232SDF34" />
      <Text strong className="text-primary" >Tu seguro personal + seguro de Vehiculo</Text>
      <br/>
      <Row justify="center">
        <Col span={18}>
          <div className="circle">
            <Title className="text-primary" level={2} strong>{cost}</Title>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <Title level={5} className="text-primary" strong>Origen / Destino</Title>
        </Col>
        <Col span={20} offset={2}>
          <div className="order-details">
            <Text strong className="text-primary">
            Machala <strong>A</strong> Cuenca
            </Text>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="map-container">
            <Map
              className={'map-summary'}
              padding={{
                bottom: 50,
                top: 50,
                left: 50,
                right: 50,
              }}
              dragpan={false}></Map>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={20} offset={2} className="block-mileage text-primary">
          <Row className="route-data">
            <Col span={6}>
              <div> 60km</div>
            </Col>
            <Col span={6}>
              <div >3.39h</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <br/>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Space direction="vertical">
            <Title level={5} className="text-primary">Fecha</Title>
            <Text  strong>Jan 27, 2020 - 1:45PM</Text>
          </Space>
        </Col>
        <Col span={24}>
          <Space direction="vertical">
            <Title level={5} className="text-primary" strong>Tipo seguro</Title>
            <Text strong>Seguro Completo</Text>
          </Space>
        </Col>
      </Row>
      <br/>
      <Content>
        <Button href="/travel" type="primary" size="large" block>VER RUTA</Button>
      </Content>
      <Divider className="no-border"/>
    </Layout>
  );

};
export default OrderDetails;
